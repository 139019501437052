import CentralFrame from "../CentralFrame";
import React, { useCallback } from "react";
import style from "./style.module.scss";
import Icon from "../Icon";
import { Mandato, Revolut, Steps } from "../../assets/icons";
import { Button } from "react-bootstrap";
import { Banks } from "../../utils/const";
interface props {
  handleSetBank?: (bank: Banks) => void;
}

const SelectBank = ({ handleSetBank }: props) => {
  const setRevolut = useCallback(() => {
    if (handleSetBank) {
      handleSetBank(Banks.REVOLUT);
    }
  }, [handleSetBank]);

  const setMandato = useCallback(() => {
    if (handleSetBank) {
      handleSetBank(Banks.MANDATO);
    }
  }, [handleSetBank]);

  return (
    <>
      <center>
        <CentralFrame>
          <p className={style.header}>Select your bank</p>
          <p className={style.steps}>
            <Icon icon={Steps} />
          </p>
          <p className={style.banks}>
            <Button
              onClick={setRevolut}
              variant="outline-dark"
              className={style.bank}
            >
              <Icon icon={Revolut} />
            </Button>
            <Button onClick={setMandato} variant="outline-dark" className={style.bank}>
              <Icon icon={Mandato} />
            </Button>
          </p>
        </CentralFrame>
      </center>
    </>
  );
};

export default SelectBank;
