import React, { useEffect, useState } from "react";
import { BaseProps } from "../../utils/const";
import api from "../../utils/api";
import CentralFrame from "../CentralFrame";

interface IMandatoComponent extends BaseProps {
  merchantKey: string;
  depositAmount: number;
}

const MandatoComponent = ({
  merchantKey,
  depositAmount,
}: IMandatoComponent) => {
    const [ progress, setProgress ] = useState(false);

  useEffect(() => {
    if (progress) { return;}
    setProgress(true);
    api
      .consumeMerchantToken(merchantKey)
      .then((response) => {
        if (response === undefined) {
          return;
        }
        if (!response.error) {            
        }
      })
      .then(() => {
        api.mandatoDeposit(merchantKey, depositAmount).then((response) => {
          if (response === undefined) {
            return;
          }
          if (!response.error) {
            window.location.href=response.data.account;
          }
        });
      });
  }, [merchantKey, depositAmount, progress]);
  return <><center><CentralFrame><center>Waiting for data</center></CentralFrame></center></>;
};

export default MandatoComponent;
