import React, { useState } from "react";
import PaymentInstructionsBox from "../../components/PaymentInstructionsBox";
import { useParams } from "react-router-dom";
import { Banks } from "../../utils/const";
import SelectBank from "../../components/SelectBank";
import MandatoComponent from "../../components/MandatoComponent";

const PaymentInstructions = () => {
  const { merchantKey, depositAmount } = useParams();

  const [bank, setBank] = useState(Banks.UNSET);

  switch (bank) {
    case Banks.REVOLUT:
      return (
        <center>
          <PaymentInstructionsBox
            depositAmount={Number(depositAmount?.replace(",", ".")) ?? 0}
            merchantKey={merchantKey ?? ""}
          ></PaymentInstructionsBox>
        </center>
      );
    case Banks.MANDATO:
      return (
        <center>
          <MandatoComponent
            depositAmount={Number(depositAmount?.replace(",", ".")) ?? 0}
            merchantKey={merchantKey ?? ""}
          ></MandatoComponent>
        </center>
      );
    default:
      return (
        <>
          <SelectBank handleSetBank={setBank} />
        </>
      );
  }
};

export default PaymentInstructions;
