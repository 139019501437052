import CentralFrame from "../../components/CentralFrame";
import CircleText from "../..//components/CircleText";
import { environment, gatewayHost } from "../../configuration";
import api from "../../utils/api";
import React, { createRef, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import style from "./style.module.scss";
import MerchantRadioBox from "../../components/MerchantRadioBox";
import BoxedTextInput from "../../components/BoxedTextInput";

const TrySettle = () => {
  const inputReference = createRef<any>();
  const [merchants, setMerchants] = useState<any>([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [currentMerchant, setCurrentMerchant] = useState("");

  const navigate = useNavigate();

  const formSubmit = useCallback((e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      merchant: { value: string };
      amount: { value: string };
    };
    const merchant = target.merchant.value.split(",");

    if (isNaN(Number.parseFloat(target.amount.value))) {
      console.log("ERROR HERE");
      return;
    }
    api
      .loginMerchant(merchant[1], environment === "dev" ? "" : "1234")
      .then((response) => {
        if (environment === "dev") {
          navigate(`/payment_instructions/${response.data.access_token}/${target.amount.value}`);
        } else {
          console.log(response);
          window.location.href = `${gatewayHost}${response.data.access_token}/${target.amount.value}`;
        }
      });
  }, [navigate]);

  const handleMerchantChange = useCallback((event: any) => {
    setCurrentMerchant(event.currentTarget.value);
  }, []);

  useEffect(() => {
    api.merchantList().then((response) => {
      setMerchants(response.data);
      setDataLoaded(true);
    });
  }, []);

  return (
    <center className="mt-5">
      <h1 className={style.h1}>Try Settlement and donate to a good cause</h1>
      <p className={style.secondline}>
        Make a quick and secure donation directly from your bank account using
        open
        <br /> banking- no fees attached.
      </p>
      <CentralFrame>
        <Form onSubmit={formSubmit}>
          <CircleText blueText="1">Pick a charity</CircleText>
          <div className={dataLoaded ? style.hidden : style.visible}>
            Loading data...
          </div>

          <div className={dataLoaded ? style.visible : style.hidden}>
            {merchants instanceof Array &&
              merchants.length > 0 &&
              merchants.map((element: any, i) => {
                const value = [element.iban, element.email];
                return (
                  <MerchantRadioBox
                    key={i}
                    group={"merchant"}
                    value={value}
                    iban={element.iban}
                    email={element.email}
                    onChange={handleMerchantChange}
                    defaultChecked={i === 0}
                    checked={
                      currentMerchant === value.toString() ||
                      (currentMerchant === "" && i === 0)
                    }
                  />
                );
              })}
          </div>
          <div className={dataLoaded ? style.visible : style.hidden}>
            <CircleText blueText="2">Choose an amount</CircleText>
            <BoxedTextInput
              ref={inputReference}
              name={"amount"}
            />
            <div className={style.info}>
              By using the service, I accept Newcorps Terms of Use, and confirm
              that I have read Newcorps Privacy Statement.
            </div>
            <div className={style.info}>
              See also the Finnish Red Cross Fundraising Permit.
            </div>
            <div className="d-grid gap-2">
              <Button variant="primary" size="lg" type="submit">
                Donate
              </Button>
            </div>
          </div>
        </Form>
      </CentralFrame>
    </center>
  );
};
export default TrySettle;
