import React, { useId } from "react";
import style from "./style.module.scss";
import RoundedBox from "../RoundedBox";

interface props {
  group: string;
  iban: string;
  email: string;
  defaultChecked?: boolean;
  value?: any;
  checked?: boolean;
  onChange?: (event: any) => void;
}
const MerchantRadioBox = ({
  iban,
  email,
  group,
  defaultChecked,
  value,
  checked,
  onChange,
}: props): React.JSX.Element => {
  const randomId = useId();

  return (
    <>
      <RoundedBox
        className={style.boxsizer}
        style={
          (checked && defaultChecked) || checked
            ? { borderColor: "#2293FB", backgroundColor: "#2293FB0D" }
            : { borderColor: "#d2d2d2", backgroundColor: undefined }
        }
      >
        <div className={style.radioboxcontainer}>
          <input
            name={group}
            type="radio"
            value={value}
            id={randomId}
            onChange={onChange}
            defaultChecked={defaultChecked}
          />
          <label htmlFor={randomId}>
            <span className="radio"></span>
          </label>
        </div>
        <div>
          <div className={style.iban}>{iban}</div>
          <div className={style.email}>{email}</div>
        </div>
      </RoundedBox>
    </>
  );
};
export default MerchantRadioBox;
