import React from "react";
import style from "./style.module.scss";

interface props {
  children?: React.ReactNode;
}

const CentralFrame = ({ children }: props): React.JSX.Element => {
  return (
    <>
        <div className={style.frame}>{children}</div>
    </>
  );
};

export default CentralFrame;
