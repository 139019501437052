import React from "react";
import style from "./style.module.scss";
import {
  githash,
  githubLink,
  telegramLink,
  twitterLink,
} from "../../../configuration";
import Icon from "../../Icon";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  EnvelopeWhite,
  GithubWhite,
  LogoFooter,
  TelegramWhite,
  TwitterWhite,
} from "../../../assets/icons";
import clsx from "clsx";

const Footer = (): React.JSX.Element => (
  <>
    <footer className={clsx(style.footer)}>
      <Container fluid className={style.container}>
        <Row>
          <Col sm={12} md={4}>
            <Row>
              <Col className="d-flex justify-content-md-start justify-content-center">
                <Icon icon={LogoFooter} />
              </Col>
            </Row>
            <Row>
              <Col
                className={clsx(
                  style.description,
                  "d-flex",
                  "justify-content-md-start",
                  "justify-content-center"
                )}
              >
                Settlement has never been easier
              </Col>
            </Row>
            <Row className="w-8">
              <Col>&nbsp;</Col>
            </Row>
            <Row>
              <Col
                className={clsx(
                  style.copyright,
                  "d-flex",
                  "justify-content-md-start",
                  "justify-content-center"
                )}
              >
                © 2023 BlockSettle
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-md-start justify-content-center">
                {githash}
              </Col>
            </Row>
          </Col>
          <Col sm={12} md lg xl>
            &nbsp;
          </Col>
          <Col sm={12} md={4} lg={5} xl={3} className="pt-md-2">
            <Container>
              <Row className="mb-3">
                <Col
                  className={clsx(
                    style.contacts,
                    "d-flex",
                    "justify-content-md-start",
                    "justify-content-center"
                  )}
                >
                  Contacts
                </Col>
              </Row>
              <Row className="mb-3">
                <Col className="d-flex justify-content-md-start justify-content-center">
                  <Button
                    variant="link"
                    href="mailto:hello@blocksettle.com"
                    className={style.email}
                  >
                    <Icon icon={EnvelopeWhite} className={style.email} />
                    hello@blocksettle.com
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-md-start justify-content-center">
                  <Link to={twitterLink}>
                    <Icon icon={TwitterWhite} />
                  </Link>
                  <Link to={telegramLink}>
                    <Icon icon={TelegramWhite} />
                  </Link>
                  <Link to={githubLink}>
                    <Icon icon={GithubWhite} />
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-md-start justify-content-center"></Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </footer>
  </>
);

export default Footer;
/*
        <Row>
          <Col>
            <Container className="justify-content-end justify-content-md-center">
              <Row>
                <div className={style.logo}>
                  <Icon icon={LogoFooter} />
                </div>
              </Row>
              <Row>Settlement has never been easier</Row>
              <Row>© 2023 BlockSettle</Row>
              <Row>{githash}</Row>
            </Container>
          </Col>
          <Col>&nbsp;</Col>
          <Col>
            <Row className="text-center">Contacts</Row>
            <Row>
              <div className="d-flex text-white">
                <Icon icon={EnvelopeWhite} className={style.email} />
                hello@blocksettle.com
              </div>
            </Row>
            <Row>
              <Col className="d-flex text-white">
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      */

/*
    <div className={style.footer}>
      <div className={style.logo}>
        <img alt="" src="/static/icons/footerlogo.svg" />
      </div>
      <div className={style.description}></div>
      <div className={style.copyright}>
        
        <br />
        
      </div>
      <div className={style.contacts}>
        <div className={style.title}>Contacts</div>
        <div className={style.email}>
          <img
            alt=""
            className="Subtract w-6 h-6 left-[-30px] top-0 absolute"
            src="/static/icons/envelope.svg"
          />
          hello@blocksettle.com
        </div>
        <div className="TwitterLogo1f34f01b1 w-6 h-6 left-0 top-[81px] absolute">
          <Icon icon={"twitter.svg"} />
        </div>
        <div className="TelegramLogoE41a62dc1 w-6 h-6 left-[34px] top-[81px] absolute">
          <Icon icon={"telegram.svg"} />{" "}
        </div>
        <div className="GithubLogoCb9681ce1 w-6 h-6 left-[68px] top-[81px] absolute">
          <Icon icon={"github.svg"} />{" "}
        </div>
      </div>
    </div>

*/
