"use client";
import React, { useCallback, useContext, useEffect, useState } from "react";

import CentralFrame from "../CentralFrame";
import { DonateContext } from "../../utils/contexts";
import { environment, settleHost } from "../../configuration";

import { BaseProps } from "../../utils/const";
import { Accordion, Button, Table } from "react-bootstrap";
import CopyToClipboard from "../CopyToClipboard";
import PaymentDetectedModal from "../Modals/PaymentDetectedModal";
import api from "../../utils/api";
import style from './style.module.scss';
import CircleText from "../CircleText";

interface IPaymentInstructionsBox extends BaseProps {
  merchantKey: string;
  depositAmount: number;
}

const PaymentInstructionsBox = ({
  merchantKey,
  depositAmount,
}: IPaymentInstructionsBox): React.JSX.Element => {
  const [depositData, setDepositData] = useState<any>();
  const donateContext = useContext(DonateContext);
  const [downloaded, setDownloaded] = useState((environment === "dev") ? false : false);
  const [loaderText, setLoaderText] = useState("Loading data....");
  const [debug, setDebug] = useState({
    id: "",
    state: "",
  });

  const [modalVisible, setModalVisible] = useState(false);

  const handleTopupClick = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const ref = depositData?.deposit_ref;

    api.topup(ref, "completed", depositData?.deposit_amount).then((response) => {
      console.log(response);
      setDebug({
        id: response.data.id,
        state: response.data.state,
      });

      setModalVisible(true);
    });
  }, [depositData]);
  
  useEffect(() => {
    api
      .consumeMerchantToken(merchantKey)
      .then((response) => {
        if (response === undefined) {
          setLoaderText("Problem downloading data");
          return;
        }
        if (!response.error) {
          donateContext.merchantMail = response.data.email;
        }
      })
      .then(() => {
        api.deposit(merchantKey, depositAmount).then((response) => {
          if (response === undefined) {
            setLoaderText("Problem downloading data");
            return;
          }
          if (!response.error) {
            setDepositData(response.data);
            donateContext.amount = response.data.amount;
            donateContext.currency = response.data.ccy;
            setDownloaded(true);
          }
        });
      });
    setDepositData({ account: [{}] });
  }, [merchantKey, depositAmount, donateContext]);
  return (
    <>
      <PaymentDetectedModal
        hasCloseButton={false}
        isOpen={modalVisible}
        onClose={() => {
          setModalVisible(false);
        }}
        onOkButtonClicked={() => {
          setModalVisible(false);
        }}
      >
        <table>
          <tbody>
            <tr>
              <td>ID</td>
              <td>{debug.id}</td>
            </tr>
            <tr>
              <td>state</td>
              <td>{debug.state}</td>
            </tr>
          </tbody>
        </table>
      </PaymentDetectedModal>

      <center>
        <CentralFrame>
          <CircleText blueText="3">Payment summary</CircleText>
          <div className={downloaded ? "ghidden" : "gvisible"}>
            <center>{loaderText}</center>
          </div>
          <div className={downloaded ? "gvisible" : "ghidden"}>
            <Table>
              <tbody>
                {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  downloaded && (
                    <>
                      <tr>
                        <td style={{backgroundColor: "#dfe2e6"}}>Merchant ID</td>
                        <td>{donateContext.merchantMail}</td>
                      </tr>
                      <tr>
                        <td style={{backgroundColor: "#dfe2e6"}}>Client amount</td>
                        <td>
                          {typeof depositData?.deposit_amount === "string"
                            ? parseFloat(
                                depositData?.deposit_amount ?? 0
                              ).toFixed(2)
                            : depositData?.deposit_amount?.toFixed(2)}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td style={{backgroundColor: "#dfe2e6"}}>Client currency</td>
                        <td> {depositData?.deposit_ccy}</td>
                      </tr>
                      <tr>
                        <td style={{backgroundColor: "#dfe2e6"}}>Merchant amount</td>
                        <td>{depositData?.amount}</td>
                      </tr>
                      <tr>
                        <td style={{backgroundColor: "#dfe2e6"}}>Merchant currency</td>
                        <td>{depositData?.merchant_ccy}</td>
                      </tr>
                      <tr>
                        <td style={{backgroundColor: "#dfe2e6"}}>Timestamp</td>
                        <td>{new Date().toLocaleString() + ""}</td>
                      </tr>
                      <tr>
                        <td style={{backgroundColor: "#dfe2e6"}}>Status</td>
                        <td>waiting deposit</td>
                      </tr>
                    </>
                  )
                }
              </tbody>
            </Table>

            <br />
            <CircleText blueText="4">Deposit instructions</CircleText>
            <br />
            <br />
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              depositData?.account?.map((element, key) => {
                return (
                  <>
                    <Table key={key} size={"lg"}>
                      <tbody
                    
                      >
                        <tr>
                          <td colSpan={1} style={{backgroundColor: "#dfe2e6"}}>Deposit Ref</td>
                          <td colSpan={1}>
                            {
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              depositData?.deposit_ref
                            }
                            &nbsp;
                            <CopyToClipboard
                              text={
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                depositData?.deposit_ref
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td style={{backgroundColor: "#dfe2e6"}}>IBAN</td>
                          <td>
                            {element?.iban}&nbsp;
                            <CopyToClipboard text={element?.iban} />
                          </td>
                        </tr>
                        <tr>
                          <td style={{backgroundColor: "#dfe2e6"}}>BIC</td>
                          <td>{element?.bic}</td>
                        </tr>
                        <tr>
                          <td colSpan={2}></td>
                        </tr>
                      </tbody>
                    </Table>
                    <Accordion className="w-full" >
                      <Accordion.Header className={style.accordion}>More info</Accordion.Header>
                      <Accordion.Body>
                        <Table size={"lg"}>
                          <tbody
                            style={{
                              borderStyle: "none",
                              borderColor: "#ffffff",
                            }}
                          >
                            <tr>
                              <td>Beneficiary: </td>
                              <td>{element?.beneficiary}</td>
                            </tr>
                            <tr>
                              <td>Beneficiary address:</td>
                              <td>
                                {element?.beneficiary_address?.street_line1},
                                {element?.beneficiary_address?.street_line2}
                                <br />
                                {element?.beneficiary_address?.city},
                                {element?.beneficiary_address?.postcode}
                                {element?.beneficiary_address?.country}
                                <br />
                              </td>
                            </tr>
                            <tr>
                              <td>Bank country:</td>
                              <td>{element?.bank_country}</td>
                            </tr>
                            <tr>
                              <td>Supported methods:</td>
                              <td>{element?.schemes?.join(",")}</td>
                            </tr>
                            <tr>
                              <td>Estimated time: </td>
                              <td>
                                From {element?.estimated_time?.min} to{" "}
                                {element?.estimated_time?.max}{" "}
                                {element?.estimated_time?.unit}
                                <br />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Accordion.Body>
                    </Accordion>
                  </>
                );
              })
            }

            {environment === "dev" ? (
              <div className="d-grid gap-2 mt-4">
                <Button variant="primary" size="lg" onClick={handleTopupClick}>
                  Simulate deposit
                </Button>
              </div>
            ) : (
              <div className="d-grid gap-2 mt-4">
                <Button
                  variant="primary"
                  size="lg"
                  href={`${settleHost}status/${depositData?.deposit_ref}`}
                >
                  Confirm
                </Button>
              </div>
            )}
          </div>
        </CentralFrame>
      </center>
    </>
  );
};

export default PaymentInstructionsBox;
