import React, { useCallback } from 'react'
import { Clipboard } from '../../assets/icons'

interface props {
  text: string
}

const CopyToClipboard = ({ text }: props): React.JSX.Element => {
  const onClickHandle = useCallback(async () => {
    await navigator.clipboard.writeText(text)
  }, [text])
  return (
    <button onClick={onClickHandle}>
      <img alt='' src={Clipboard} width={15} height={15} />
    </button>
  )
}
export default CopyToClipboard
