import {
  apiHost,
  googleArea,
  googleDomain,
  googleProject,
} from "../configuration";
import { ApiResponse } from "../models/ApiResponse";

enum RequestType {
  GET = "GET",
  POST = "POST",
}

export class MerchantApi {
  headers = {};
  token = "";
  constructor() {
    this.setHeader("Content-Type", "application/json");
  }

  setHeader(key: string, value: string): void {
    Object.assign(this.headers, { [key]: value });
  }

  async sendGoogleRequest(
    endpoint: string,
    type: RequestType,
    data?: any
  ): Promise<ApiResponse> {
    return await fetch(
      `https://${googleArea}-${googleProject}.${googleDomain}/${endpoint}`,
      {
        method: type.toString(),
        headers: this.headers,
        body: JSON.stringify(data),
      }
    )
      .then(async (response) => {
        //      const isJson = response.headers.get('content-type')?.includes('application/json')
        const data = await response.json();
        if (!response.ok) {
          const error = data ?? response.status;
          return await Promise.reject(error);
        }
        // ASSUMING RESPONSE IS JSON
        // Siraj has to change conten-type header
        return data;
      })
      .catch((error) => {
        console.error("Error message:", error);
      });
  }

  async sendRequest(
    endpoint: string,
    type: RequestType,
    data?: any
  ): Promise<ApiResponse> {
    return await fetch(
      `${apiHost}/${endpoint}`,
      {
        method: type.toString(),
        headers: this.headers,
        body: JSON.stringify(data),
      }
    )
      .then(async (response) => {
        //      const isJson = response.headers.get('content-type')?.includes('application/json')
        const data = await response.json();
        if (!response.ok) {
          const error = data ?? response.status;
          return await Promise.reject(error);
        }
        // ASSUMING RESPONSE IS JSON
        // Siraj has to change conten-type header
        return data;
      })
      .catch((error) => {
        console.error("Error message:", error);
      });
  }

  async sendMandatoRequest(
    endpoint: string,
    type: RequestType,
    data?: any
  ): Promise<ApiResponse> {
    return await fetch(
      `https://merchant.blocksettle.com/${endpoint}`,
      {
        method: type.toString(),
        headers: this.headers,
        body: JSON.stringify(data),
      }
    )
      .then(async (response) => {
        //      const isJson = response.headers.get('content-type')?.includes('application/json')
        const data = await response.json();
        if (!response.ok) {
          const error = data ?? response.status;
          return await Promise.reject(error);
        }
        // ASSUMING RESPONSE IS JSON
        // Siraj has to change conten-type header
        return data;
      })
      .catch((error) => {
        console.error("Error message:", error);
      });
  }
  // ANONYMOUS REQUEST
  signupMerchant = async (
    email: string,
    password: string,
    name: string,
    iban: string,
    publicKeys: string[]
  ): Promise<ApiResponse> => {
    const payload = {
      email,
      password,
      iban,
      name,
      public_keys: publicKeys,
    };
    if (this.headers.hasOwnProperty("Authorization")) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete this.headers["Authorization"];
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    const response = await this.sendGoogleRequest(
      "signup",
      RequestType.POST,
      payload
    );
    if (!response.error) {
      this.token = response.data.access_token;
      this.setHeader("Authorization", `Bearer ${this.token}`);
    }

    return response;
  };

  async merchantList(): Promise<ApiResponse> {
    if (this.headers.hasOwnProperty("Authorization")) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete this.headers["Authorization"];
    }

    const response = await this.sendGoogleRequest(
      "fetchmerchants",
      RequestType.GET
    );

    return response;
  }

  async consumeMerchantToken(token: string): Promise<ApiResponse> {
    const payload = { token };

    if (this.headers.hasOwnProperty("Authorization")) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete this.headers["Authorization"];
    }
    const response = await this.sendRequest(
      "api/v1/authorize",
      RequestType.POST,
      payload
    );
    return response;
  }

  async payMerchant(
    token: string,
    iban: string,
    amount: number
  ): Promise<ApiResponse> {
    const payload = {
      token,
      iban,
      amount,
    };
    const response = await this.sendRequest(
      "api/v1/pay",
      RequestType.POST,
      payload
    );
    return response;
  }

  async loginMerchant(email: string, password: string): Promise<ApiResponse> {
    const payload = {
      email,
      password,
    };
    if (this.headers.hasOwnProperty("Authorization")) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete this.headers["Authorization"];
    }
    const response = await this.sendGoogleRequest(
      "get_token",
      RequestType.POST,
      payload
    );
    if (!response.error) {
      this.token = response.data.access_token;
    }
    return response;
  }

  async deposit(token: string, amount: number): Promise<ApiResponse> {
    const payload = {
      access_token: token,
      amount,
    };
    this.token = token;
    if (!this.headers.hasOwnProperty("Authorization")) {
      this.setHeader("Authorization", `Bearer ${this.token}`);
    }

    const response = await this.sendRequest(
      "api/v1/deposit",
      RequestType.POST,
      payload
    );
    return response;
  }

  async mandatoDeposit(token: string, amount: number): Promise<ApiResponse> {
    const payload = {
      access_token: token,
      amount,
    };
    this.token = token;
    if (!this.headers.hasOwnProperty("Authorization")) {
      this.setHeader("Authorization", `Bearer ${this.token}`);
    }

    const response = await this.sendRequest(
      "api/v1/mandato/deposit",
      RequestType.POST,
      payload
    );
    return response;
  }

  async topup(
    ref: string,
    state: string,
    amount: number
  ): Promise<ApiResponse> {
    const payload = {
      ref,
      state,
      amount,
    };
    if (this.headers.hasOwnProperty("Authorization")) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete this.headers["Authorization"];
    }

    const response = await this.sendRequest(
      "api/v1/merchant/topup",
      RequestType.POST,
      payload
    );
    return response;
  }
}

const api = new MerchantApi();
export default api;
