import React from 'react'
import style from './style.module.scss'

interface props {
  children?: React.ReactNode
  blueText?: string
}

const CircleText = ({ children, blueText }: props): React.JSX.Element => {
  return (
    <>
      <div className={style.container}>
        <div className={style.numberparent}>
          <span className={style.number}>{blueText}</span>
        </div>
        <div className={style.header}>{children}</div>
      </div>
    </>
  )
}
export default CircleText
