import { BaseProps } from "../../utils/const";
import Footer from "./Footer";
import Header from "./Header";
import Chessboard1 from "./Chessboard1";
import Chessboard2 from "./Chessboard2";
import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";
import React from "react";

const PageLayout = ({ children }: BaseProps) => {
  return (
    <>
      <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
        <Header />
        <Chessboard1 />
          <Chessboard2 />
        <Container className="flex-grow-1 z-3 mb-4">
          <Outlet />
        </Container>
        <Footer></Footer>
      </div>
    </>
  );
};

export default PageLayout;
