import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { LogoSvg } from "../../../assets/icons";
import Icon from "../../Icon";
import { NavLink } from "react-router-dom";
import { merchantportalHost } from "../../../configuration";
import style from "./style.module.scss";
import clsx from "clsx";
import React from "react";

const Header = () => {
  return (
    <>
      <Navbar
        className={style.mainheader}
        expand="lg"
      >
        <Container fluid>
          <Navbar.Brand href="/">
            <Icon className={style.logo} icon={LogoSvg} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className=" justify-content-end"
          >
            <Nav className="ml-auto">
              <Nav.Link className={style.navlink} as={NavLink} to={`${merchantportalHost}/admin`}>
                Login
              </Nav.Link>
              <Nav.Link as="div" className={clsx(style.navlink, style.active)}>
                Get settled
              </Nav.Link>
              <Nav.Item
                className={clsx(style.navbutton)}
                as={Button}
                variant="outline-dark"
                href={`${merchantportalHost}/contact-sales`}
              >
                Contact Sales
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
