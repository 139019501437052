import { createBrowserRouter } from "react-router-dom";
import React from "react";
import TrySettle from "../pages/trysettle";
import PageLayout from "../components/PageLayout";
import PaymentInstructions from "../pages/PaymentInstructions";
import Register from "../pages/Register";

const router = createBrowserRouter([
  {
    path: "/",
    Component: PageLayout,
    children: [
      {
        path: "",
        element: <TrySettle />,
      },
      {
        path: "payment_instructions/:merchantKey/:depositAmount",
        element: <PaymentInstructions />,
      },
      {
        path: "register",
        element: <Register />,
      },
    ],
  },
]);

export default router;
