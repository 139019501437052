import React, { forwardRef } from "react";

import style from "./style.module.scss";
import Icon from "../Icon";
import { EUR } from "../../assets/icons";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";

interface props {
  onChange?: (event: any) => void;
  name: string;
}

const BoxedTextInput = forwardRef((props: props, ref: any) => {
  return (
    <>
      <div>
        <InputGroup className="mt-3">
          <FloatingLabel
            controlId="contactSalesMessage"
            label="Enter amount"
            className={style.flabel}
          >
            <Form.Control
              ref={ref}
              autoFocus
              name={props.name}
              type="number"
              placeholder="Message"
              className={style.input}
              onChange={props.onChange}
              step={0.01}              
              required
            />
            <Form.Control.Feedback type="invalid">
              Enter valid amount
            </Form.Control.Feedback>
          </FloatingLabel>
          <InputGroup.Text>
            <Icon icon={EUR} className={style.suffiximg} />
            EUR
          </InputGroup.Text>
        </InputGroup>
      </div>
    </>
  );
});

export default BoxedTextInput;
